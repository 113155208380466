import { useEffect, useState } from 'react'
import Spinner from '../Common/Spinner'
import CardProductoDisponible from '../Card/CardProductoDisponible'
import { useCart } from '../Hooks/useCart'
import getPlantsFromCart from '../../utils/getPlantsFromCart'

/*const recomended = [
  {
    id: '432e5f5d-0db9-4b78-9273-4194380036ca',
    price: 10000,
    image_1: 'https://media.istockphoto.com/id/1475314230/es/foto/planta-de-aloe-vera.jpg?s=1024x1024&w=is&k=20&c=vA4AHp2lOdwhExwmGlsmryGlfktyXWL9kUDYaRbS3no=',
    name: 'Producto de prueba',
    description: 'Short Description',
    initial_quantity: 13
  }
]*/

export default function RecomendedProducts() {
  const [ popularProducts, setPopularProducts ] = useState([])
  const { cart } = useCart()
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    const plantsFromCart = getPlantsFromCart(cart)

    if (plantsFromCart.plants.length > 0) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recomended-pots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(plantsFromCart), // Enviar plantas en el body
      })
        .then((response) => response.json())
        .then((data) => {
          setPopularProducts(data.pots) // Asignar la respuesta de la API a popularProducts
        })
        .catch((error) => {
          console.error('Error fetching recommended products:', error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setPopularProducts([]) // Usar productos de prueba si no hay plantas
      setLoading(false)
    }
  }, [ cart ])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className='hidden md:mt-8 md:block'>
      <h3 className='text-lg font-bold tracking-tight text-gray-600 uppercase'>Productos Recomendados</h3>
      <div className='mt-6 grid grid-cols-3 gap-4 sm:mt-8'>
        { popularProducts.map((product) => (
          <div key={ product.id }>
            <CardProductoDisponible producto={ product } />
          </div>
        )) }
      </div>
    </div>
  )
}
