export const initialState = {
  productos: [],
  filteredProductos: [],
  searchQuery: '',
  selectedCategory: '',
  selectedSubcategories: [], // Ahora es un array para múltiples subcategorías
  sortOrder: '',
  loading: false,
  error: null
};

export const searchFormReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_PRODUCTS_START':
      return { ...state, loading: true, error: null };

    case 'FETCH_PRODUCTS_SUCCESS':
      return {
        ...state,
        productos: action.payload,
        filteredProductos: action.payload, // Inicialmente todos los productos
        loading: false
      };

    case 'FETCH_PRODUCTS_ERROR':
      return { ...state, loading: false, error: action.payload };

    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };

    case 'SET_CATEGORY':
      return { ...state, selectedCategory: action.payload, selectedSubcategories: [] }; // Reseteamos subcategorías al cambiar categoría

    case 'SET_SUBCATEGORY':
      // Si la subcategoría ya está seleccionada, la removemos; si no, la agregamos
      const alreadySelected = state.selectedSubcategories.includes(action.payload);
      const updatedSubcategories = alreadySelected
        ? state.selectedSubcategories.filter(subcategory => subcategory !== action.payload)
        : [ ...state.selectedSubcategories, action.payload ];

      return { ...state, selectedSubcategories: updatedSubcategories };

    case 'SET_SORT_ORDER':
      return { ...state, sortOrder: action.payload };

    case 'APPLY_FILTERS':
      const { productos, searchQuery, selectedCategory, selectedSubcategories, sortOrder } = state;

      let filtered = productos;

      // Aplicar filtro de búsqueda
      if (searchQuery) {
        filtered = filtered.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      // Aplicar filtro de categoría
      if (selectedCategory) {
        filtered = filtered.filter((product) =>
          product.categories.some(category => category.id === parseInt(selectedCategory))
        );
      }
      // Aplicar filtro de subcategoría
      if (selectedSubcategories.length > 0) {
        filtered = filtered.filter((product) =>
          product.subcategories.some(subcategory =>
            selectedSubcategories.includes(subcategory.id) // Comprobamos si alguna subcategoría coincide
          )
        );
      }

      // Ordenar por precio
      if (sortOrder === 'asc') {
        filtered = filtered.sort((a, b) => a.price - b.price);
      } else if (sortOrder === 'desc') {
        filtered = filtered.sort((a, b) => b.price - a.price);
      }

      return { ...state, filteredProductos: filtered };

    default:
      return state;
  }
};
