import { useContext } from 'react';
import { SearchFormContext } from '../Context/SearchFormContext';

export const useSearchForm = () => {
  const { state, dispatch } = useContext(SearchFormContext);

  const setSearchQuery = (query) => {
    dispatch({ type: 'SET_SEARCH_QUERY', payload: query });
    dispatch({ type: 'APPLY_FILTERS' });
  };

  const setCategory = (category) => {
    dispatch({ type: 'SET_CATEGORY', payload: category });
    dispatch({ type: 'APPLY_FILTERS' });
  };

  const setSubcategory = (subcategory) => {
    dispatch({ type: 'SET_SUBCATEGORY', payload: subcategory });
    dispatch({ type: 'APPLY_FILTERS' });
  };

  const setSortOrder = (order) => {
    dispatch({ type: 'SET_SORT_ORDER', payload: order });
    dispatch({ type: 'APPLY_FILTERS' });
  };

  const applyFilters = () => {
    dispatch({ type: 'APPLY_FILTERS' });
  };

  return {
    state,
    setSearchQuery,
    setCategory,
    setSubcategory,
    setSortOrder,
    applyFilters,
  };
};
