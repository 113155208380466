import React from 'react';
import { Link } from 'react-router-dom';  // Importación añadida para que funcione el Link
import './css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  const logo = "https://ezcbvdobzemkdzwavevc.supabase.co/storage/v1/object/public/lalocadelasplantas/logos/Tienda%20Digital%20(6).png";
  return (
    <div className="footer">
      <div className="logo-container-footer">
        <img src={logo} alt="Imagen Logo" className='logo-footer' />

      </div> 
      <div className='column-footer-description'> 
          <p className='p1'>La Loca de las Plantas</p> 
          <p className='p2'>Ubicado en el corazón Viña del Mar, nuestro vivero urbano se extiende a cada rincón de sus hogares </p>
          <Link to="/Blog" className='p1'>Blog</Link>
      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Contacto</p> 
        <p className="contact-info">1/2 Poniente 1316, Viña del Mar, Chile</p>
        <p className="contact-info">+569 6565 81 72</p>
        <p className="contact-info"></p>
        <p className="contact-info">javieraklug@gmail.com</p>
        
      </div>
      <div className="column-footer-rows">
        <p className="contact-title">Encuentranos en</p>       
        <div className="column-footer-columns gap-x-5">
          <a href="https://www.instagram.com/lalocadelasplantas.cl/" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faInstagram} size="xl" className="text-white" />
          </a>
          <a href="https://wa.me/56952011773?text=Hola,%20quisiera%20más%20información%20de%20lalocadelasplantas.cl" target="_blank" rel="noopener noreferrer" className="rrss">
            <FontAwesomeIcon icon={faWhatsapp} size="xl" className="text-white" />
          </a>
        </div> 
      
      </div> 

    </div>
  );
};

export default Footer;