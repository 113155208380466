export default function getPlantsFromCart(cartItems) {
  const plants = cartItems
    .filter(item =>
      Array.isArray(item.categories) &&
      item.categories.some(category => category.category === 'Plantas')
    )
    .map(item => ({
      id: item.id,
      range_pot_diameter_max: item.range_pot_diameter_max,
      range_pot_diameter_min: item.range_pot_diameter_min
    }));

  return { plants };
}
