import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa el hook useNavigate de React Router
import SidebarHeader from '../Sidebar/SidebarHeader'; // Importa el componente SidebarHeader
import './css/header.css'; // Importa el archivo de estilos CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Header = ({ scrollPosition }) => {
  const navigate = useNavigate(); // Inicializa el hook useNavigate

  const headerClass = scrollPosition === 1 ? 'header-solid' : 'header-transparent';
  const logoWhite = "https://ezcbvdobzemkdzwavevc.supabase.co/storage/v1/object/public/lalocadelasplantas/logos/Tienda%20Digital%20(6).png";
  const logoBlack = "https://ezcbvdobzemkdzwavevc.supabase.co/storage/v1/object/public/lalocadelasplantas/logos/Tienda%20Digital%20(5).png";

  const logo = scrollPosition === 1 ? logoBlack : logoWhite; // Selecciona el logo en función de scrollPosition

  const handleBack = () => {
    navigate(-1); // Navega hacia atrás en el historial
  };

  return (
    <header className={headerClass}>
      <div className="logo-container-header">
        <a href="/">
          <img src={logo} alt="Logo de la empresa" className="logo" />
        </a>
      </div>

      <div className="button-container">
        <button onClick={handleBack} className="flex flex-row items-center text-elqui"><FontAwesomeIcon className='text-xl pr-2' icon={faCircleArrowLeft} />Volver</button>
      </div>
    </header>
  );
};

export default Header;