import { BeatLoader } from 'react-spinners';

export default function Spinner(loading) {
  return (
    <div className="flex justify-center items-center h-24">
      <BeatLoader
        color='#0e9f6e'
        loading={ loading }
        size={ 16 }
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};