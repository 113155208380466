import CardProductoDisponible from './CardProductoDisponible';
import { useSearchForm } from '../Hooks/useSearchForm';

const ListaProductosDisponibles = () => {
  const { state: { filteredProductos, loading, error } } = useSearchForm();

  if (loading) {
    return <p>Cargando productos...</p>;
  }

  if (error) {
    return <p>Error al cargar productos: { error }</p>;
  }

  return (
    <div className='w-full bg-gray-100 p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
      { filteredProductos.length > 0 ? (
        filteredProductos.map(producto => (
          <div key={ producto.id } className='flex justify-center'>
            <CardProductoDisponible producto={ producto } />
          </div>
        ))
      ) : (
        <p className='textSinDisponibilidad text-center w-full col-span-full text-elqui'>
          No hay productos disponibles.
        </p>
      ) }
    </div>

  );
};

export default ListaProductosDisponibles;

//