import React, { createContext, useReducer, useEffect } from 'react';
import { searchFormReducer, initialState } from '../Reducers/SearchForm';

export const SearchFormContext = createContext();

export const SearchFormProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(searchFormReducer, initialState);

  // Hacer el fetch de productos en el useEffect
  useEffect(() => {
    const fetchProductos = async () => {
      dispatch({ type: 'FETCH_PRODUCTS_START' });

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        dispatch({ type: 'FETCH_PRODUCTS_SUCCESS', payload: data.product || [] });
      } catch (error) {
        dispatch({ type: 'FETCH_PRODUCTS_ERROR', payload: error.message });
      }
    };

    fetchProductos();
  }, []);

  return (
    <SearchFormContext.Provider value={ { state, dispatch } }>
      { children }
    </SearchFormContext.Provider>
  );
};
