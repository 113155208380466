import React from 'react'
import { faBell, faCalendarCheck, faGear, faHotel, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

import './css/Anfitrion.css';

const features = [
  {
    name: 'Unidades de alojamiento',
    description:
      'Gestione y actualice la disponibilidad y características de cada unidad con facilidad, manteniendo a sus huéspedes informados.',
    icon: faHotel,
    link: "/unidades"
  },
  {
    name: 'Reservas',
    description:
      'Administre todas sus reservas en un solo lugar y realice actualizaciones en tiempo real para una experiencia perfecta.',
    icon: faCalendarCheck,
    link: "/reservas"
  },
  {
    name: 'Pagos',
    description:
      'Supervise sus ingresos con seguridad, garantizando un seguimiento claro y preciso de todos los pagos.',
    icon: faMoneyBill,
    link: "/pagos"
  },
  {
    name: 'Notificaciones',
    description:
      'Reciba alertas en tiempo real sobre reservas, pagos y cualquier actualización importante en su hotel.',
    icon: faBell,
    link: "/notificaciones"
  },
  {
    name: 'Configuración de cuenta',
    description:
      'Personalice su perfil, administre preferencias y mantenga su cuenta segura con opciones avanzadas.',
    icon: faGear,
    link: "/configuracion"
  },
]



export default function Anfitrion() {
  return (
    <div className="bg-white min-h-screen w-screen py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <img src="https://lqieogqayryfoykmpoey.supabase.co/storage/v1/object/public/imagenes/LogoBucontPMS.png?t=2024-08-14T21%3A08%3A51.141Z" alt="Logo Bucont" className="mx-auto h-24 sm:h-32" />
          <h2 className="text-base font-semibold leading-7 text-yellow-500 bg-">Bienvenido, Bucont</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 bg sm:text-4xl">
            Gestione su hotel de manera eficiente
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Todo lo que necesita para administrar su hotel de forma sencilla y eficaz está a su alcance. Controle cada aspecto de su negocio desde un solo panel intuitivo y optimizado.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-yellow-500">
                    <FontAwesomeIcon icon={feature.icon} aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  <Link to={`/admin${feature.link}`} className="textLink text-xl text-yellow-600 hover:text-yellow-500 hover:underline">{feature.name}</Link>
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600 text-">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>

      </div>
    </div>
  )
}