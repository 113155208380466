import React, { useState, useEffect } from 'react';
import Spinner from '../Common/Spinner';
import { useSearchForm } from '../Hooks/useSearchForm';

export default function SearchForm() {
  const [ categories, setCategories ] = useState([]);
  const [ subcategories, setSubcategories ] = useState([]);
  const [ loadingFetch, setLoadingFetch ] = useState(true);
  const [ activeSubcategories, setActiveSubcategories ] = useState([]);
  const [ showSubcategories, setShowSubcategories ] = useState(false)
  const {
    state: { searchQuery, selectedCategory, selectedSubcategories, sortOrder },
    setSearchQuery,
    setCategory,
    setSubcategory,
    setSortOrder,
  } = useSearchForm();

  // Fetch de categorías y subcategorías
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_BACKEND_URL.replace(/\/$/, '');
    const fetchCategoriesAndSubcategories = async () => {
      try {
        const categoriesResponse = await fetch(`${apiUrl}/api/categories`);
        const subcategoriesResponse = await fetch(`${apiUrl}/api/subcategories`);

        const categoriesData = await categoriesResponse.json();
        const subcategoriesData = await subcategoriesResponse.json();

        setCategories(categoriesData.categories);
        setSubcategories(subcategoriesData.subcategories);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingFetch(false);
      }
    };

    fetchCategoriesAndSubcategories();
  }, []);

  useEffect(() => {
    const condition = selectedCategory && (subcategories.filter(subcategory => subcategory.categoryId === Number(selectedCategory)).length > 0)
    setShowSubcategories(condition)
  }, [ selectedCategory ])

  // Maneja el cambio de categoría y resetea las subcategorías seleccionadas
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setActiveSubcategories([]); // Resetea las subcategorías activas al cambiar la categoría
  };

  // Maneja el click en una subcategoría (activando/desactivando)
  const handleSubcategoryToggle = (subcategoryId) => {
    setActiveSubcategories((prevActive) =>
      prevActive.includes(subcategoryId)
        ? prevActive.filter((id) => id !== subcategoryId) // Si ya está activa, la desactivamos
        : [ ...prevActive, subcategoryId ] // Si no está activa, la activamos
    );
    setSubcategory(subcategoryId)
  };

  if (loadingFetch) {
    return <Spinner />;
  }

  return (
    <div className="p-5 w-screen max-w-screen-md">
      <div className="flex flex-col">
        <div className="rounded-xl border border-gray-200 bg-white p-6 shadow-lg">
          <form onSubmit={ (e) => e.preventDefault() }>
            {/* Barra de búsqueda */ }
            <div className="relative mb-10 w-full flex items-center justify-between rounded-md">
              <svg
                className="absolute left-2 block h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
              <input
                type="text"
                name="search"
                className="h-12 w-full cursor-text rounded-md border border-gray-100 bg-gray-100 py-4 pr-40 pl-12 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Busca tu producto"
                value={ searchQuery }
                onChange={ (e) => setSearchQuery(e.target.value) }
              />
            </div>

            {/* Filtros */ }
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              {/* Filtro por Categoría */ }
              <div className="flex flex-col">
                <label htmlFor="category" className="text-sm font-medium text-stone-600">Categoria</label>
                <select
                  id="category"
                  value={ selectedCategory }
                  onChange={ handleCategoryChange }
                  className="mt-2 block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                >
                  <option value="">Elije una opción</option>
                  { categories.map((category) => (
                    <option key={ category.id } value={ category.id }>
                      { category.category }
                    </option>
                  )) }
                </select>
              </div>
              {/* Ordenar por precio */ }
              <div className="flex flex-col">
                <label htmlFor="sortOrder" className="text-sm font-medium text-stone-600">Ordenar por Precio</label>
                <select
                  id="sortOrder"
                  value={ sortOrder }
                  onChange={ (e) => setSortOrder(e.target.value) }
                  className="mt-2 block w-full rounded-md border border-gray-100 bg-gray-100 px-2 py-2 shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                >
                  <option value="">Elije una opción</option>
                  <option value="asc">Ascendente</option>
                  <option value="desc">Descendente</option>
                </select>
              </div>
            </div>

            {/* Filtro por Subcategoría */ }
            { showSubcategories && (
              <div className="mt-6">
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-stone-600">Subcategorías</label>
                  <div className="mt-2 grid grid-cols-2 gap-2 md:grid-cols-3">
                    { subcategories
                      .filter((subcategory) => subcategory.categoryId == selectedCategory)
                      .map((subcategory) => (
                        <button
                          key={ subcategory.id }
                          type="button"
                          onClick={ () => handleSubcategoryToggle(subcategory.id) }
                          className={ `
                            px-4 py-2 rounded-lg border text-sm font-medium transition-colors
                            ${selectedSubcategories.includes(subcategory.id)
                              ? 'bg-green-600 text-white border-green-600'
                              : 'bg-gray-100 text-gray-600 border-gray-300 hover:bg-gray-200'
                            }
                          `}
                        >
                          { subcategory.subcategory }
                        </button>
                      )) }
                  </div>
                </div>
              </div>
            ) }

            {/* Botones de reset y búsqueda */ }
            <div className="mt-6 grid w-full justify-end space-x-4 md:flex text-sm">
              <button
                type="button"
                onClick={ () => {
                  setCategory('');
                  setActiveSubcategories([]);
                  setSortOrder('');
                } }
                className="rounded-lg bg-green-700 px-8 py-2 font-medium text-white outline-none hover:opacity-80 focus:ring"
              >
                Borrar Filtros
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
