import React, { useState, useEffect } from 'react';
//import { useShoppingCart } from '../Context/ShoppingCartContext';
import formatPrice from '../../utils/formatPrice';
import { useCart } from '../Hooks/useCart';
import { useNotification } from '../Hooks/useNotification';

export default function CardProductoDisponible({ producto }) {
  const [ productoState, setProductoState ] = useState(producto);
  const { addToCart, cart } = useCart()
  const { addNotification } = useNotification()
  const [ stock, setStock ] = useState(null); // Estado para almacenar el stock actual

  /*
  const { addItemToCart } = useShoppingCart();
  const handleAddItem = () => {
    addItemToCart(productoState);
  };
  */

  const handleAddItem = () => {
    const newProduct = {
      ...productoState,
      quantity: 1,
      set: false,
      selectedColor: ''
    }
    // Buscar si el producto ya está en el carrito
    const existingProduct = cart.find(item => item.id === producto.id);

    // Cantidad total que se tendría si se añade el nuevo producto
    const totalQuantity = existingProduct ? existingProduct.quantity + newProduct.quantity : newProduct.quantity;

    // Verificar si la cantidad total supera el stock disponible
    if (totalQuantity > stock) {
      alert("No hay suficiente stock disponible.");
      return; // Salir de la función si el stock es insuficiente
    }
    addToCart(newProduct)
    addNotification()
  }

  useEffect(() => {
    if (!productoState.cantidad) {
      setProductoState({ ...productoState, cantidad: 1 });
    }

    // Fetch para obtener el stock actual desde el backend
    const fetchStock = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/stock/${producto.id}`);
        if (!response.ok) {
          throw new Error(`Error al obtener el stock: ${response.statusText}`);
        }
        const data = await response.json();
        setStock(data.currentStock); // Actualizar el estado con el stock actual
      } catch (error) {
        console.error('Error fetching stock:', error);
      }
    };

    fetchStock(); // Llamar al fetch cuando el componente se monte
  }, [ producto, productoState ]);

  return (
    <div className="relative w-full max-w-xs overflow-hidden rounded-lg bg-white shadow-md flex flex-col">
      <div className="relative overflow-hidden h-60 rounded-t-lg">
        <img
          className="w-full h-full object-cover"
          src={ producto.image_1 }
          alt={ producto.name }
        />
        <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-30 transition-opacity duration-300 ease-in-out"></div>
      </div>
      { stock === 0 && (
        <span className="absolute top-0 left-0 w-28 translate-y-4 -translate-x-6 -rotate-45 bg-black text-center text-xs text-white">
          No Disponible
        </span>
      ) }
      <div className="mt-4 px-5 pb-5 flex flex-col flex-grow">

        <h5 className="text-xl font-semibold tracking-tight text-slate-900">
          { producto.name }
        </h5>
        <p className="text-sm text-gray-500 mt-1">
          Unidades disponibles:
          <span className="font-semibold text-gray-700">
            { stock !== null ? ` ${stock}` : 'Cargando inventario...' }
          </span>
        </p>
        <div className="mt-2.5 mb-5 flex flex-wrap gap-2">
          { producto.categories && producto.categories.map(cat => (
            <span key={ cat.id } className="rounded bg-green-300 px-2.5 py-0.5 text-xs font-semibold max-w-[120px] truncate">
              { cat.category }
            </span>
          )) }
          { producto.subcategories && producto.subcategories.map(subcat => (
            <span key={ subcat.id } className="rounded bg-gray-100 px-2.5 py-0.5 text-xs font-semibold max-w-[120px] truncate">
              { subcat.subcategory }
            </span>
          )) }
        </div>
        <div className="mt-auto flex flex-col items-center justify-between gap-2">
          <p>
            <span className="text-3xl font-bold text-slate-900">{ formatPrice(producto.price) }</span>
          </p>
          <button
            onClick={ handleAddItem }
            disabled={ stock === 0 }
            className={ `flex items-center justify-center w-36 rounded-md px-5 py-2.5 text-center text-sm font-medium focus:outline-none focus:ring-4 ${stock === 0
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed' // Estilos cuando está deshabilitado
              : 'bg-gray-800 text-white hover:bg-gray-600 focus:ring-blue-300'
              }` }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            Añadir
          </button>
        </div>
      </div>
    </div>
  );
}
