import ProductCart from '../components/Cart/ProductCart'
import OrderSumary from '../components/Cart/OrderSumary'
import { useCart } from '../components/Hooks/useCart'
import { useNotification } from '../components/Hooks/useNotification'
import { useEffect } from 'react'
import Header from '../components/Header/header'
import './css/Compra.css';
import RecomendedProducts from '../components/Cart/RecomendedProducts'

/*const cart = [
  {
    id: 1,
    quantity: 3,
    discountPercentage: 0,
    price: 10000,
    thumbnail: {
      src: 'https://media.istockphoto.com/id/1475314230/es/foto/planta-de-aloe-vera.jpg?s=1024x1024&w=is&k=20&c=vA4AHp2lOdwhExwmGlsmryGlfktyXWL9kUDYaRbS3no=',
      alt: 'Imagen'
    },
    title: 'Producto de prueba',
    shortDescription: 'Short Description',

  }
]*/

export default function Cart() {
  const { cart } = useCart()
  const { clearNotification } = useNotification()

  useEffect(() => {
    clearNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Header scrollPosition={ 1 } className="fixed-header" />
      <section className='fade-in bg-white py-4 px-6 antialiased dark:bg-gray-900 md:py-6'>
        <div className='mx-auto mt-20 max-w-screen-xl px-4 2xl:px-0'>
          <h2 className='text-xl font-bold tracking-tight text-gray-600 uppercase dark:text-white'>
            Tu Carrito
          </h2>
          <p className='py-2 text-gray-600'>
            Los artículos en tu carrito no están reservados. Termina el proceso de compra o puedes <a href='/tienda' className='text-blue-600 underline'>seguir comprando.</a>
          </p>
          <div className='mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8'>
            <div className='mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl'>
              {
                cart.map((product) => (
                  <ProductCart key={ product.id } item={ product } />
                ))
              }
              <RecomendedProducts />
            </div>

            <div className='mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full'>
              <OrderSumary />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
