import './css/FormularioPago.css';
import HeaderFormulario from '../components/Header/headerFormulario';
import FormPay from '../components/Form/FormPay';
import Footer from '../components/Footer/Footer';

const FormularioPago = () => {
    return (
        <div className="flex flex-col h-full items-center bg-gray-100" >
            <HeaderFormulario scrollPosition={ 1 } />
            <div className="my-32 rounded-3xl mx-2">
                <FormPay />
            </div>
            <div className='outer-div-Footer'>
                <Footer />
            </div>
        </div>
    );
};

export default FormularioPago;